import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import appConfig from '../../utils/appConfig';

const ThankYouPage: React.FC = () => {
  const location = useLocation();
  const [paymentData, setPaymentData] = useState({
    response: '',
    userId: '',
    sum: '',
    email: '',
    confirmationCode: ''
  });

  // חילוץ נתונים מה-URL ושמירת נתונים ב-Session Storage
  useEffect(() => {
    const params = new URLSearchParams(location.search);
  
    // יצירת אובייקט ריק לשמירת כל הפרמטרים מה-URL
    let allParams: { [key: string]: any } = {};
  
    // מעבר על כל הפרמטרים ב-URL והוספתם לאובייקט
    for (let [key, value] of params.entries()) {
      allParams[key] = value;
    }
  
    console.log(allParams); // הדפסה של כל הפרמטרים שנמצאו
  
    const storedData = sessionStorage.getItem('paymentDetails');
    if (storedData) {
      const parsedData = JSON.parse(storedData);
  
      // שילוב כל הפרמטרים מה-URL עם המידע ששמור ב-Session Storage
      const dataToSend = {
        ...allParams, // כל הפרמטרים מ-URL
        ...parsedData // הנתונים השמורים ב-Session Storage
      };
  
      // שליחת נתונים לשרת אם הקוד תגובה הוא 000
      if (dataToSend.response === '000') {
        console.log(dataToSend);
        axios.post(appConfig.dataUrl, dataToSend)
          .then(response => {
            console.log('Data sent successfully:', response.data);
          })
          .catch(error => {
            console.error('Error sending data:', error);
          });
      }
  
      // עדכון הסטייט עם המידע החדש
      setPaymentData({
        response: dataToSend.response,
        userId: dataToSend.userId,
        sum: dataToSend.sum,
        email: dataToSend.email,
        confirmationCode: dataToSend.confirmationCode
      });
    }
  }, [location.search]);
  

  return (
    <Box sx={{ textAlign: 'center', marginTop: 4 }}>
      {paymentData.response === '000' ? (
        <>
          <Typography variant="h4" sx={{ color: '#7b1213', fontWeight: 'bold' }}>
            התשלום עבר בהצלחה! תודה
          </Typography>
          <Typography variant="h6" sx={{ color: '#7b1213', fontWeight: 'normal' }}>
            מספר אישור {paymentData.confirmationCode}
          </Typography>
          <Typography variant="h6" sx={{ color: '#7b1213', fontWeight: 'normal' }}>
            אישור תשלום ישלח במייל
          </Typography>
        </>
      ) : (
        <Typography variant="h6" sx={{ color: '#7b1213', fontWeight: 'normal' }}>
          ארעה שגיאה בתהליך התשלום, אנא נסו שוב, באם התקלה חוזרת פנו אלינו בטופס צור קשר
        </Typography>
      )}
    </Box>
  );
};

export default ThankYouPage;
