const terms_approval:{ [key: string]: string }  = {
    

lod_buses_terms_approval:`
    <h2>נוהל רישום להסעות עבור תלמידים שאינם זכאים משרד החינוך</h2>
<h3>שנה"ל תשפ"ה</h3>
<p>1. כל התלמידים, הן הזכאים והן שאינם זכאים להסעות במימון משרד החינוך חייבים ברישום במערכת ההסעות של העירייה. תלמיד שלא ירשם עד לתאריך 15/8/24 לא יישמר לו מקום מובטח בהסעה.</p>
<p>2. מספר המקומות בהסעות מוגבל ויעבוד בשיטת "כל הקודם זוכה".</p>
<p>3. התשלום להסעות בתוך העיר עבור תלמידים שאינם זכאים להסעות עפ"י נהלי משרד החינוך, הינו 1,900 ₪ לשנת לימודים.</p>
<p>4. התשלום יתבצע במעמד הרישום להסעות וכתנאי לו.</p>
<p>5. ניתן לשלם באשראי, שיקים או מזומן עפ"י הפירוט הבא:</p>
<p>- באשראי – עד 10 תשלומים ללא תפיסת מסגרת.</p>
<p>- בשיקים – עד 10 שיקים שיינתנו בפעם אחת במחלקת הגבייה של העירייה (לא ניתן לשלם תשלום חלקי).</p>
<p>- מזומן – תשלום אחד בלבד במחלקת הארנונה.</p>
<p>6. לא ניתן לשלם תשלום חלקי עבור הסעה בתוך העיר לצד אחד.</p>
<p>7. תלמידים הלומדים מחוץ לעיר יקבלו הסעה בכיוון אחד בלבד עבור תשלום של 950 ₪ לשנת לימודים.</p>
<p>8. אין החזרים במהלך השנה. מכיוון שהעירייה מזמינה הסעות לכל השנה לפי הרישום להסעות, לא ניתן לקבל החזרים כלל גם במידה והילד עוזב את ההסעה או את בית הספר. ילד שנרשם להסעות חייב בתשלום מלא ומראש עד סוף השנה.</p>
<p>9. אין הנחות על תשלום ההסעות. התשלום הינו מסובסד בחלקו ועל כן לא יהיו הנחות כלל.</p>
<p>10. ההסעות אינן מלוות ע"י אחראי מטעם העירייה.</p>
<p>11. רק תלמידים זכאי משרד החינוך או שנרשמו ושילמו מראש כאמור, יורשו לעלות להסעות.</p>
            `,


  };
  
  export default terms_approval;
  