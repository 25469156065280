

const appConfig = {
  version: "0.0.1",
  apiUrls: {
  //  baseUrl: "http://localhost:5000/api",
  //baseUrl: "https://buses-test-1bd6696d27a8.herokuapp.com/api",
    baseUrl: process.env.REACT_APP_API_URL,
    //baseUrl: getBaseUrl(),
    newFormUrl: function() { return `${this.baseUrl}/newForm`; },
  },

 price: "1900",

  authorities: [
    // {
    //   en: 'eliad',
    //   he: 'אליעד',
    //   tranzilaCode: 'eliad100',
    //   tranzilaCodeTok: 'eliad100',
    // },
    {
      en: 'lod',
      he: 'לוד',
      tranzilaCode: 'lodhtz',
      tranzilaCodeTok: 'lodhtz',
    },
  ],
//בשימוש
  checkIdUrl: "https://hook.eu1.make.com/1w66i7f7qd8j6souxqxt7rklyzxq62av",
  dataUrl: "https://hook.eu1.make.com/kj4s5kwum4fhg84gvatdjgzd1sk7g3x6",
  //לא בשימוש כרגע
  tempPaymentUrl: "https://hook.eu1.make.com/xlk9b7ttr53hv5dywt1fr1smt79x4yep",
  cashOrCheckPaymentUrl: "https://hook.eu1.make.com/pkyz72bk0i5pku174oj7cwqbfa554q8v",


  //page_title: function() { return `רישום צהרונים ${this.year} - עמותת ${this.authorityHebrewName}`; },

  service: function() {
    return `
      office@htz-tech.co.il <i class="fas fa-envelope"></i>  לשירות לקוחות:  
       <i class="fab fa-whatsapp"></i>  058-5511320 
        
    `;
  },
  
  

  is_mobile: /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent),
};

function getBaseUrl() {
  if (process.env.NODE_ENV === 'development') {
    return "http://localhost:5000/api";
  } else if (process.env.NODE_ENV === 'test') {
    return "https://afternoontest-665b0e2d97b7.herokuapp.com/api";
  } else if (process.env.REACT_APP_API_URL) {
    return process.env.REACT_APP_API_URL;
  } else {
    return "https://default-production-url.com/api";
  }
}

export default appConfig;


