import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { Typography, Box, CircularProgress } from '@mui/material';
import appConfig from '../../utils/appConfig';

const NoPaymentThankYouPage: React.FC = () => {
  const location = useLocation();
  const serverData = location.state?.serverData;
  const idNumber = location.state?.idNumber;
  const userEmail = location.state?.email;
  const selectedStation = location.state?.selectedStation; 
  const acceptTerms = location.state?.acceptTerms; 

  useEffect(() => {
    const sendData = async () => {
      try {
        await axios.post(appConfig.dataUrl, {
          serverData,
          idNumber,
          userEmail,
          selectedStation,
          acceptTerms
        });
      } catch (error) {
        console.error('Failed to send data:', error);
      }
    };

    sendData();
  }, [serverData, idNumber, userEmail,acceptTerms,selectedStation]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
      <Typography variant="h4" gutterBottom>
        תהליך ההרשמה הסתיים בהצלחה
      </Typography>
    </Box>
  );
};

export default NoPaymentThankYouPage;
