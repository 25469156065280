const stations = {
    "stationsList": [
      { "id": 1, "name": "רחוב שבטי ישראל תחנה 1" },
      { "id": 2, "name": "רחוב שבטי ישראל תחנה" },
      { "id": 3, "name": "רחוב בן שמן" },
      { "id": 4, "name": "דרך לוד הירוקה" },
      { "id": 5, "name": "רחוב איילון" },
      { "id": 6, "name": "רחוב שפירא(מול קופת חולים מכבי)" },
      { "id": 7, "name": "רחוב הפרחים" },
      { "id": 8, "name": "רחוב הפרחים פינת ציפורן" },
      { "id": 9, "name": "רחוב אקסודוס(מול המכינה)" },
      { "id": 10, "name": "רחוב פלומניק" },
      { "id": 11, "name": "רחוב גורדון" },
      { "id": 12, "name": "רחוב שלמה המלך( פלאפל שמעוני)" },
      { "id": 13, "name": "רחוב דוד המלך(ספריה ישנה)" },
      { "id": 14, "name": "רחוב חטיבת יפתח(ליד הקיוסק)" },
      { "id": 15, "name": "רחוב איינשטין" },
      { "id": 16, "name": "רחוב ההסתדרות פינת אדיסון" },
      { "id": 17, "name": "רחוב ההסתדרות מול היכל התרבות" },
      { "id": 18, "name": "רחוב ארלוזורוב פינת זבוטינסקי" },
      { "id": 19, "name": "רחוב ארלוזורוב ליד מעפילים" },
      { "id": 20, "name": "רחוב דרך הרמא שכונת נאות יצחק" },
      { "id": 21, "name": "רחוב יוספטל (שער העיר )" },
      { "id": 22, "name": "רחוב סן מרטין" },
      { "id": 23, "name": "רחוב יחד שבטי ישראל תחנה 1- יחד שבטי ישראל 10" },
      { "id": 24, "name": "רחוב יחד שבטי ישראל תחנה 2 - יחד שבטי ישראל 40" },
      { "id": 25, "name": "רחוב שד ירושלים מול כיבוי אש" },
      { "id": 26, "name": "נופי בן שמן-שכונה חדשה" },
      { "id": 27, "name": "גני אביב שכונת חדשה" },
      { "id": 28, "name": "גני אביב תחנת אוטובס סמוך לדרכא" }
    ]
}

export default stations;