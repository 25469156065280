// src/components/Footer.tsx
import React, { useState } from 'react';
import { Grid, Typography, Modal, Box, Card, CardContent, TextField, Button } from '@mui/material';
import { useAdmin } from '../../AdminContext'; // עדכון הנתיב בהתאם
import appConfig from '../../utils/appConfig';
import logo from '../../assets/images/logo.png';
import pci from '../../assets/images/pci.png';
import './Footer.css';
import terms_approval from '../../utils/termsAproval';

const Footer: React.FC = () => {
  const [open, setOpen] = useState<boolean>(false);
  const [adminOpen, setAdminOpen] = useState<boolean>(false);
  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const { isAdmin, setIsAdmin } = useAdmin();

  const handleAdminLogin = () => {
    if (username === 'hagay' && password === '1234') {
      setIsAdmin(!isAdmin);
      setAdminOpen(false);
    } else {
      alert('שם משתמש או סיסמא שגויים');
    }
  };

  return (
    <>
    <Box sx={{ textAlign: 'left', padding: '5px', backgroundColor: '#bbb1b1'}}>
        <Typography sx={{ fontSize: "14px" ,textAlign: 'center' }} dangerouslySetInnerHTML={{ __html: appConfig.service() }} />
      </Box>
      <div className="Footer">
        
        <Grid container spacing={2} justifyContent={"space-between"} alignItems={"center"} className="section-0">
          
          <Grid item lg={4} className="section-1">
          <Button variant="text" onClick={() => setAdminOpen(true)}  style={{
                color: 'black',
                textAlign: 'left',
                fontSize: '14px',
                position: 'fixed',
                bottom: '20px', // המרחק מהקצה התחתון של המסך
                left: '20px', // המרחק מהקצה השמאלי של המסך
              }}>
              כניסת מנהל
            </Button>
            {terms_approval.billagan_terms_approval &&
              <Typography 
                sx={{ textDecoration: "underline", fontSize: "14px", margin: 0, padding: 0, cursor: "pointer" }} 
                variant="body2" 
                onClick={() => setOpen(!open)}
              >
                תנאי שימוש
              </Typography>
            }
            
            <Modal open={open} onClose={() => setOpen(false)} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
              <Box 
                sx={{ 
                  overflowY: "auto", 
                  position: 'absolute', 
                  top: '50%', 
                  left: '50%', 
                  transform: 'translate(-50%, -50%)', 
                  maxHeight: "90vh", 
                  maxWidth: "95vw", 
                  width: "800px" 
                }}
              >
                <Card>
                  <CardContent>
                    <Typography 
                      sx={{ textAlign: "left !important" }} 
                      variant="body1" 
                      dangerouslySetInnerHTML={{ __html: terms_approval.billagan_terms_approval }} 
                    />
                  </CardContent>
                </Card>
              </Box>
            </Modal>

            <Modal open={adminOpen} onClose={() => setAdminOpen(false)} aria-labelledby="admin-modal-title" aria-describedby="admin-modal-description">
            <Box 
      sx={{ 
        position: 'absolute', 
        top: '50%', 
        left: '50%', 
        transform: 'translate(-50%, -50%)', 
        width: 400, 
        bgcolor: 'background.paper', 
        boxShadow: 24, 
        p: 4, 
        textAlign: 'center' // מרכז את כל התוכן בתוך ה-Box
      }}
    >
      <Typography id="admin-modal-title" variant="h6" component="h2">
        {isAdmin ? 'ניתוק מנהל' : 'כניסת מנהל'}
      </Typography>
      <TextField 
        fullWidth 
        label="שם משתמש" 
        variant="outlined" 
        margin="normal"
        value={username}
        onChange={(e) => setUsername(e.target.value)}
      />
      <TextField 
        fullWidth 
        label="סיסמא" 
        type="password" 
        variant="outlined" 
        margin="normal"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <Button 
        variant="contained" 
        color="primary" 
        onClick={handleAdminLogin}
        sx={{ mt: 2 }} // הוסף מרווח עליון לכפתור
      >
        {isAdmin ? 'יציאה' : 'כניסה'}
      </Button>
    </Box>
            </Modal>
          </Grid>

          <Grid item lg={4} className="section-3" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <Typography fontSize={14}>תשלום מאובטח</Typography>
            <img src={pci} alt="PCI" width={80} height={50} style={{ objectFit: "contain" }} />
          </Grid>
          
          <Grid item className="section-3">
            <img src={logo} alt="Logo" width={180} style={{ marginRight: "auto" }} />
          </Grid>
        </Grid>
      </div>
      
      
    </>
  );
};

export default Footer;
