import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import CryptoJS from 'crypto-js';
import './Dashboard.css';
import appConfig from '../../utils/appConfig';
import { Typography, TextField, Button, CircularProgress, Box, FormControl, InputLabel, Select, MenuItem, FormControlLabel, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import terms_approval from '../../utils/termsAproval';
import stations from '../../utils/stations';
import Paper from '@mui/material/Paper';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { useAdmin } from '../../AdminContext';

interface Account {
  id: string;
  Account_Name: string;
  field2: string;
  logo_link: string | null;
  main_org: string;
  name_to_web: string;
  if_pay_hok_bank: boolean;
}

const Dashboard: React.FC = () => {
  const navigate = useNavigate();
  const [idNumber, setIdNumber] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [result, setResult] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [idError, setIdError] = useState<string | null>(null);
  const [emailError, setEmailError] = useState<string | null>(null);
  const [phoneError, setPhoneError] = useState<string | null>(null);
  const [selectedStation, setSelectedStation] = useState('');
  const [mosadName, setMosadName] = useState<string | null>(null);
  const [acceptTerms, setAcceptTerms] = useState<boolean>(false);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [externalSchoolsForm, setExternalSchoolsForm] = useState<boolean>(false);
  const [serverData, setServerData] = useState({
    name: '',
    institution: '',
    price: '',
    eligible: '',
  });

  const [grade, setGrade] = useState('');
  const [parentName, setParentName] = useState('');
  const [phone, setPhone] = useState('');

  const amount: number = Number(appConfig.price) / 2;

  const { isAdmin } = useAdmin();

  const { authorityName } = useParams<{ authorityName: string }>();
  const authority = appConfig.authorities.find(auth => auth.en === authorityName);

  const stationOptions = stations.stationsList.map(station => station.name);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    if (name === 'idNumber') {
      setIdNumber(value);
      validateIdNumber(value);
    } else if (name === 'email') {
      setEmail(value);
      validateEmail(value);
    }
    else if (name.includes("Phone") || name.includes("phone")) {
      setPhone(value);
      validatePhone(value);
    }
  };

  const isValidIsraeliID = (id: string): boolean => {
    if (id.length !== 9) return false;
    return Array.from(id, Number).reduce((counter, digit, i) => {
      const step = digit * ((i % 2) + 1);
      return counter + (step > 9 ? step - 9 : step);
    }) % 10 === 0;
  };
  
  const validateIdNumber = (value: string) => {
    if (!/^\d*$/.test(value)) {
      setIdError('ניתן להקיש ספרות בלבד');
    } else if (value.length === 9) {
      if (isValidIsraeliID(value)) {
        setIdError(null); // מנקה שגיאות כאשר מספר תקין
        } else {
          setIdError('מספר תעודת זהות לא תקין');
        }
    } else {
      setIdError(value.length > 0 ? 'מספר תעודת זהות לא תקין' : '');
    }
  };
  

  const validateEmail = (value: string) => {
    if (!/\S+@\S+\.\S+/.test(value)) {
      setEmailError('כתובת אימייל לא תקינה');
    } else {
      setEmailError(null);
    }
  };

  const validatePhone = (value: string) => {
    if (!/^\d*$/.test(value)) {
      setPhoneError('ניתן להקיש ספרות בלבד');
    } else if (!value.startsWith('05') || value.length !== 10) {
      setPhoneError('מספר טלפון לא תקין, יש להקליד עשר ספרות בתבנית: 0501234567');
    }
    else {
      setPhoneError(null);
    }
  };

  const handleCheckId = async () => {
    setLoading(true);
    setError(null);
    setResult(null);


    try {
        const response = await fetch(appConfig.checkIdUrl, {
            method: 'POST',
            body: idNumber,
        });

        if (response.ok) {
            const data = await response.json(); // ניתוח תגובה מהשרת כ-JSON
            const mosadName = data[0]?.mosad_name;
            setMosadName(mosadName);

            // בדיקה אם המוסד הוא 'מעוז חיים' או 'צופיה'
               /// if (mosadName === 'מעוז חיים' || mosadName === 'צופיה') {
                if (true) {
                if (Array.isArray(data) && data.length > 0) {
                    setServerData({
                        name: `${data[0].first_name} ${data[0].last_name}`,
                        institution: mosadName,
                        price: appConfig.price,
                        eligible: data[0].zakai !== '' ? data[0].zakai : 'לא זכאי',
                    });

                    if (data[0].zakai === "זכאי") {
                        console.log("הוא זכאי");
                    }
                } else {
                    const errorText = data.value || 'נתונים לא תקינים מהשרת';
                    setError(errorText);
                }
            } else {
                // הקפצת פופאפ אם המוסד הוא לא 'מעוז חיים' או 'צופיה'
                alert('שלום וברכה!\nהרישום להסעות נסגר - לבירורים נוספים יש לפנות למחלקת ההסעות בטלפון 089279995');
                return; // לא לבצע את setServerData
            }
        } else {
            const errorText = await response.text(); // קריאה לתגובה כטקסט
            setError(errorText || 'הפרטים אינם תואמים');
        }
    } catch (err) {
        let errorMessage = 'אירעה שגיאה במהלך הבדיקה';

        if (err instanceof Error) {
            errorMessage = err.message;
        } else if (err && typeof err === 'object' && 'message' in err) {
            errorMessage = (err as any).message;
        }

setError(errorMessage);
} finally {
setLoading(false);
}
};

  const handlePayment = (method: string) => {
    if ((!selectedStation&&!externalSchoolsForm) || !acceptTerms) {
      setError('יש למלא את כל השדות ולהסכים לתנאים');
      return;
    }
    

    console.log('Selected Station:', selectedStation);
  
    if (method === "pay_hok_cc") {
      navigate(`/${authority?.en}/creditcardHok`, { state: { idNumber, email, selectedStation, serverData,acceptTerms} });
    } else if (method === "pay_cc") {
      navigate(`/${authority?.en}/creditcardPay`, { state: { idNumber, email,selectedStation, serverData,acceptTerms,grade,parentName,phone,amount ,externalSchoolsForm} });
    } else if (method === "cash_or_check") {
      navigate(`/${authority?.en}/cashOrCheckPay`, { state: { idNumber, email,selectedStation, serverData,acceptTerms,grade,parentName,phone,amount ,externalSchoolsForm} });
    } else if (method === "noPayment") {
      navigate(`/${authority?.en}/NoPaymentThankYouPage`, { state: { idNumber, email, selectedStation, serverData ,acceptTerms} });
    }
  };
  

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleLabelClick = (event: any) => {
    event.preventDefault();
    handleDialogOpen();
  };

  const phoneNumber = '972585511320'; // מספר בפורמט בינלאומי ללא ה-0 הראשון
  const message = 'שלום, אני מעוניין לרשום ילד שאינו במערכת.';
  
  const handleWhatsAppClick = () => {
    const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
    window.open(whatsappUrl, '_blank');
  };

  // סטייט עבור ניהול האינפוט הפתוח ושם המוסד
const [editInstitution, setEditInstitution] = useState(false);
const [newInstitution, setNewInstitution] = useState(serverData.institution);

const handleInstitutionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  setNewInstitution(event.target.value);
};

const handleInstitutionUpdate = () => {
  // עדכון ה-serverData עם השם החדש של המוסד
  serverData.institution = newInstitution;
  setEditInstitution(false);
};

const handleExternalSchoolsForm = () => {
  alert('שלום וברכה!\nהרישום להסעות נסגר - לבירורים נוספים יש לפנות למחלקת ההסעות בטלפון 089279995');
                return; // לא לבצע את setServerData
  //setExternalSchoolsForm(true);
};

const grades = ['א', 'ב', 'ג', 'ד', 'ה', 'ו', 'ז', 'ח', 'ט', 'י', 'י"א', 'י"ב'];
  

  return (
    <div className="dashboard-container">
      {!authorityName && (
        <div className="error-message">
          <h1>יש לרשום את שם הרשות באנגלית בסוף ה-URL</h1>
        </div>
      )}
      {authorityName && serverData.name === '' &&!externalSchoolsForm&& (
        <>
          <Typography align="center" variant="h1">
            ברוכים הבאים למערכת רישום <br></br> להסעות בתי ספר עיריית {authority?.he}
          </Typography>
          <Typography align="center" variant="h5">
            אנא הקש פרטי זיהוי של התלמיד
          </Typography>
          <TextField
            fullWidth
            label="מספר תעודת זהות של התלמיד"
            variant="outlined"
            margin="normal"
            name="idNumber"
            value={idNumber}
            onChange={handleChange}
            inputProps={{ style: { textAlign: 'center' } }}
            error={!!idError} // מציג שגיאה אם יש
            helperText={idError} // מראה את הודעת השגיאה
          />
          <TextField
            fullWidth
            label="אימייל לקבלת חשבונית"
            variant="outlined"
            margin="normal"
            type="email"
            name="email"
            value={email}
            onChange={handleChange}
            inputProps={{ style: { textAlign: 'center' } }}
            error={!!emailError} // מציג שגיאה אם יש
            helperText={emailError} // מראה את הודעת השגיאה
          />
          {error && (
            <Typography color="error">
              {error}
            </Typography>
          )}
          <Box display="flex" flexDirection="column" gap="16px">
          <Button
            variant="contained"
            color="primary"
            onClick={handleCheckId}
            disabled={loading || idError !== null|| emailError !== null||!idNumber||!email}
            style={{ marginTop: '16px' }}
          >
            {loading ? <CircularProgress size={24} /> : 'כניסה'}
          </Button>
          {/* כפתור לרישום ילד שלומד מחוץ לעיר */}
          <Button
            variant="contained"
            color="secondary"
            onClick={handleExternalSchoolsForm}
            style={{ marginTop: '16px' }}
          >
            הסעות לבתי ספר מחוץ לעיר
          </Button>
          {/* כפתור לרישום ילד שאינו במערכת */}
          <Button
              variant="outlined"
              color="secondary"
              onClick={handleWhatsAppClick}
              style={{ marginTop: '16px' }}
            >
              רישום ילד שאינו במערכת <WhatsAppIcon style={{ marginLeft: '8px', fontSize: '15px' }} />
            </Button>
            </Box>
        </>
      )}
      {externalSchoolsForm && (
       <Box
       display="flex"
       flexDirection="column"
       justifyContent="center"
       alignItems="center"
       height="100vh"
       textAlign="center"
     >
       <Typography variant="h4" gutterBottom>
         טופס הסעות לבתי ספר מחוץ לעיר
       </Typography>
       <FormControl fullWidth margin="normal">
         <InputLabel id="school-select-label">בחר בית ספר</InputLabel>
         <Select
           labelId="school-select-label"
           value={serverData.institution}
           onChange={(e) => setServerData({ ...serverData, institution: e.target.value })}

           label="בחר בית ספר"
         >
           <MenuItem value="אלעד בנים">אלעד בנים</MenuItem>
           <MenuItem value="אלעד בנות">אלעד בנות</MenuItem>
           <MenuItem value="רחובות מעיין חי">רחובות מעיין חי</MenuItem>
           <MenuItem value="בני ברק">בני ברק</MenuItem>
         </Select>
       </FormControl>
        <TextField
            fullWidth
            label="מספר תעודת זהות של התלמיד"
            variant="outlined"
            margin="normal"
            name="idNumber"
            value={idNumber}
            onChange={handleChange}
            inputProps={{ style: { textAlign: 'center' } }}
            error={!!idError} // מציג שגיאה אם יש
            helperText={idError} // מראה את הודעת השגיאה
          />
          
        <TextField
         fullWidth
         label="שם התלמיד"
         variant="outlined"
         margin="normal"
         value={serverData.name}
         onChange={(e) => setServerData({ ...serverData, name: e.target.value })}
       />
       <FormControl fullWidth margin="normal">
        <InputLabel id="grade-select-label">בחר כיתה</InputLabel>
        <Select
          labelId="grade-select-label"
          value={grade}
          onChange={(e) => setGrade(e.target.value as string)}
          label="בחר כיתה"
        >
          {grades.map((grade, index) => (
            <MenuItem key={index} value={grade}>{`כיתה ${grade}`}</MenuItem>
          ))}
        </Select>
      </FormControl>
        <TextField
         fullWidth
         label="שם ההורה"
         variant="outlined"
         margin="normal"
         value={parentName}
         onChange={(e) => setParentName(e.target.value)}
       />
        <TextField
            fullWidth
            label="אימייל לקבלת חשבונית"
            variant="outlined"
            margin="normal"
            type="email"
            name="email"
            value={email}
            onChange={handleChange}
            inputProps={{ style: { textAlign: 'center' } }}
            error={!!emailError} // מציג שגיאה אם יש
            helperText={emailError} // מראה את הודעת השגיאה
          />
       <TextField
         fullWidth
         label="טלפון נייד"
         variant="outlined"
         margin="normal"
         name="phone"
         value={phone}
         onChange={handleChange}
         inputProps={{ style: { textAlign: 'center' } }}
         error={!!phoneError} // מציג שגיאה אם יש
         helperText={phoneError} // מראה את הודעת השגיאה
       />
        <FormControlLabel
    control={
      <Checkbox
        checked={acceptTerms}
        onChange={(e) => setAcceptTerms(e.target.checked)}
      />
    }
    label={
      <span style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        <span>*</span>
        <span 
          onClick={handleLabelClick}
          style={{ marginRight: '4px', textDecoration: 'underline' }} // הוספת קו תחתון
        >
          אישור תנאי רישום
        </span>
      </span>
    }
    style={{ justifyContent: 'flex-end', textAlign: 'right' }}
  />
       <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 2 }}>
       {isAdmin && (
          <Button
            variant="contained"
            color="primary"
            onClick={() => handlePayment("cash_or_check")}
            disabled={(!selectedStation&&!externalSchoolsForm) || !acceptTerms}
          >
            תשלום במזומן/ המחאה
          </Button>
        )}
        <Button
          variant="contained"
          color="primary"
          onClick={() => handlePayment("pay_cc")}
          disabled={
            loading || emailError !== null || idError !== null || phoneError !== null ||
            !idNumber || !email || !serverData.institution || !grade ||
            !parentName || !phone || !acceptTerms
          }
        >
          תשלום באשראי
        </Button>
        
      </Box>
     </Box>
      )}
       {serverData.name!=='' &&!externalSchoolsForm&& (
  <Box
  sx={{
    position: 'relative',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    m: 'auto',
    textAlign: 'center'
  }}
>
  <Paper
    sx={{
      padding: 3,
      borderRadius: 2,
      boxShadow: 4,
      border: '1px solid #ccc', // הוספת מסגרת נוספת
      textAlign: 'left',
      marginTop: 2,
      marginBottom: 2
    }}
    >
      <Typography variant="body1">
        <span style={{ fontWeight: 'bold' }}>שם:</span> {serverData.name}
      </Typography>
      <Typography variant="body1">
        <span style={{ fontWeight: 'bold' }}>מספר ת"ז:</span> {idNumber}
      </Typography>
      <Typography variant="body1">
        <span style={{ fontWeight: 'bold' }}>מוסד:</span> {serverData.institution}
      </Typography>
      <Typography variant="body1">
        <span style={{ fontWeight: 'bold' }}>מחיר:</span> {serverData.price}
      </Typography>
      <Typography variant="body1">
        <span style={{ fontWeight: 'bold' }}>זכאות ע"פ משרד החינוך:</span> {serverData.eligible}
      </Typography>
    </Paper>
     {/* כפתור לשינוי שם המוסד */}
     {/* <Button
  variant="outlined"
  color="secondary"
  onClick={() => setEditInstitution(!editInstitution)}
  style={{ marginTop: '16px' }}
>
  {editInstitution ? 'סגירה' : 'שינוי שם מוסד'}
</Button> */}

  {/* משבצת אינפוט לשם מוסד חדש */}
  {editInstitution && (
    <Box mt={2}>
      <TextField
        fullWidth
        label="שם מוסד חדש"
        variant="outlined"
        value={newInstitution}
        onChange={handleInstitutionChange}
        InputProps={{
          sx: { textAlign: 'center' ,direction: 'ltr'}, // יישור הטקסט לימין
        }}
       
      />
      <Button
        variant="contained"
        color="primary"
        onClick={handleInstitutionUpdate}
        style={{ marginTop: '8px' }}
      >
        עדכן מוסד
      </Button>
    </Box>
  )}
        <Box mt={2} sx={{ textAlign: 'left' }}>  {/* הוספת מרווח עליון */}
  <FormControl fullWidth>
    <InputLabel id="station-select-label">בחר תחנה</InputLabel>
    <Select
      labelId="station-select-label"
      value={selectedStation}
      onChange={(e) => setSelectedStation(e.target.value as string)}
      label="בחר תחנה"
      required
      MenuProps={{
        PaperProps: {
          sx: {
            textAlign: 'left',
            direction: 'ltr',
            marginTop: '10px',
          },
        },
      }}
    >
      {stationOptions.map((station, index) => (
        <MenuItem key={index} value={station}>
          {station}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
</Box>
    <FormControlLabel
    control={
      <Checkbox
        checked={acceptTerms}
        onChange={(e) => setAcceptTerms(e.target.checked)}
      />
    }
    label={
      <span style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        <span>*</span>
        <span 
          onClick={handleLabelClick}
          style={{ marginRight: '4px', textDecoration: 'underline' }} // הוספת קו תחתון
        >
          אישור תנאי רישום
        </span>
      </span>
    }
    style={{ justifyContent: 'flex-end', textAlign: 'right' }}
  />
  <Dialog open={dialogOpen} onClose={handleDialogClose}>
  <DialogTitle sx={{ textAlign: 'center' }}>תנאי רישום</DialogTitle>
  <DialogContent sx={{ textAlign: 'left' }}>
    {/* התוכן של תנאי הרישום כאן */}
    <Typography
      variant="body1"
      style={{ textAlign: 'right', direction: 'rtl' }}
      dangerouslySetInnerHTML={{ __html: terms_approval.lod_buses_terms_approval }}
    />
  </DialogContent>
  <DialogActions>
    <Button onClick={handleDialogClose} sx={{ margin: '0 auto' }}>סגירה</Button>
  </DialogActions>
</Dialog>

  <div style={{ display: 'flex', justifyContent: 'center', gap: '16px', marginTop: '16px' }}>
    {(serverData.eligible !== "זכאי")&&((!selectedStation.includes('גני אביב')||(mosadName==='מעוז חיים'))) ? (
      <>
      {isAdmin && (
        <Button
          variant="contained"
          color="primary"
          onClick={() => handlePayment("cash_or_check")}
          disabled={!selectedStation || !acceptTerms}
        >
          תשלום במזומן/ המחאה
        </Button>
        )}
        <Button
          variant="contained"
          color="primary"
          onClick={() => handlePayment("pay_cc")}
          disabled={!selectedStation || !acceptTerms}
        >
          תשלום באשראי
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => handlePayment("pay_hok_cc")}
          disabled={!selectedStation || !acceptTerms}
        >
          תשלום בהוק באשראי
        </Button>
      </>
    ) : (
      <Button
        variant="contained"
        color="primary"
        onClick={() => handlePayment("noPayment")}
        disabled={!selectedStation || !acceptTerms}
        style={{ marginTop: '16px' }}
      >
        הרשם
      </Button>
    )}
  </div>

  {error && <Typography variant="body2" color="error.main" style={{ marginTop: '16px' }}>{error}</Typography>}
</Box>
      )}
    </div>
  );
};

export default Dashboard;
