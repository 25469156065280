import React from 'react';
import { useLocation } from 'react-router-dom';
import { Typography, Container, Link } from '@mui/material';

const ThankYouPageHokBankPay: React.FC = () => {
  const location = useLocation();
  const { state } = location;
  const success = state?.success;
  const retrievalKey = state?.retrievalKey;

  return (
    <Container style={{ textAlign: 'center', marginTop: '50px', direction: 'rtl' }}>
      <Typography variant="h4">
        {success ? 'טפסי ההרשמה נשלחו בהצלחה!' : 'ארעה תקלה בשליחה הטפסים, אנא נסה מאוחר יותר'}
      </Typography>
      {success && retrievalKey && (
        <Typography variant="h6" style={{ marginTop: '20px' }}>
          המסמך שלך זמין להורדה: <br />
          <Link href={`https://my.tranzila.com/api/get_financial_document/${retrievalKey}`} target="_blank" rel="noopener noreferrer">
            לחץ כאן להורדת המסמך
          </Link>
        </Typography>
      )}
    </Container>
  );
};

export default ThankYouPageHokBankPay;
